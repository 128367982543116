import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DxTextBoxComponent } from 'devextreme-angular';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'otk-cmp-login-textbox-senha',
    templateUrl: './cmp-login-textbox-senha.component.html',
    styleUrls: ['./cmp-login-textbox-senha.component.scss'],
})
export class CmpLoginTextboxSenhaComponent implements OnInit {
    @Input() label: string = 'Sua senha';
    @Input() control: FormControl = new FormControl(null, []);
    @Input() minLength: number = null;
    @Input() maxLength: number = null;
    @Input() width: string = '100%';
    @Input() placeholder: string = null;
    @Input() showClearButton: boolean = false;
    @Input() focus$: Subject<boolean> = new Subject<boolean>();
    @Input() help: string = null;
    @Input() widthHelp: string = '300px';
    @Input() passwordMode: string = 'password';
    @Input() passwordCompare: string = null;
    @Input() compare: boolean = false;
    @Input() errorRequired: string = `Campo obrigatório`;
    @Input() errorInvalid: string = `A senha não atende todos os requisitos.`;
    @Output() onInput = new EventEmitter();

    @ViewChild('ipt') inputSenha: DxTextBoxComponent;

    passwordButton;
    passwordButtonOff;
    popoverVisible: boolean;

    constructor() {
        this.passwordButton = {
            template:
                '<img src="../../../../../assets/img/gestio/icons/eye-slash.svg" width="16px" height="16px">',
            type: 'normal',
            onClick: () => {
                this.passwordMode =
                    this.passwordMode === 'text' ? 'password' : 'text';
            },
        };

        this.passwordButtonOff = {
            template:
                '<img src="../../../../../assets/img/gestio/icons/eye.svg" width="16px" height="16px">',
            type: 'normal',
            onClick: () => {
                this.passwordMode =
                    this.passwordMode === 'text' ? 'password' : 'text';
            },
        };
    }

    ngOnInit() {
        this.focus$.pipe(take(1)).subscribe((r: any) => {
            setTimeout(() => {
                if (this.inputSenha && this.inputSenha.instance) {
                    this.inputSenha.instance.focus();
                }
            }, 800);
        });
    }

    ngOnDestroy() {}

    ngOnChanges() {}

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }

    passwordComparison = () => {
        return this.passwordCompare;
    };

    errorMinLength(): string {
        if (!this.minLength) {
            return `Senha inválida.`;
        }

        return `Deve possuir no mínimo ${this.minLength} caracteres.`;
    }

    errorMaxLength(): string {
        if (!this.maxLength) {
            return `Senha inválida.`;
        }

        return `Deve possuir no máximo ${this.maxLength} caracteres.`;
    }

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            if (this.control.errors.confirmPasswordError) {
                return { message: 'As senhas não conferem' };
            }
            if (this.control.errors.errorInvalid) {
                return { message: 'A senha não atende todos os requisitos.' };
            }
            if (this.control.errors.required) {
                return { message: this.errorRequired };
            } else {
                return {
                    message: this.errorInvalid
                        ? this.errorInvalid
                        : `Inválido.`,
                };
            }
        }
    }

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }

    actionOnInput(event) {
        if (!event) return;
        this.onInput.emit(event.event.target.value);
    }
}
