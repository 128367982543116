import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    RouterStateSnapshot,
} from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AppRoutingService } from '../../app-routing.service';
import { AuthUser } from '../models/auth-user.model';
import { AuthService } from '../services/auth.service';

@Injectable()
export class CarregarBaseDemonstracaoGuard implements CanActivate, CanLoad {
    constructor(
        private _routingService: AppRoutingService,
        private _authService: AuthService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this._podeExecutarFluxoInicialDemonstracao();
    }

    canLoad(route: Route): Observable<boolean> {
        return this._podeExecutarFluxoInicialDemonstracao();
    }

    private _podeExecutarFluxoInicialDemonstracao(): Observable<boolean> {
        const carregarBaseDemonstracao$ = combineLatest<
            [AuthUser, boolean, boolean]
        >([
            this._authService.usuarioAutenticado$,
            this._authService.ambienteDemonstracao$,
            this._authService.executarCarregarBaseModeloDemonstracao$,
        ]);

        return carregarBaseDemonstracao$.pipe(
            take(1),
            map(([usr, ambienteDemo, carregarBase]) => {
                if (!usr || !usr.autenticado) {
                    console.log('[BASE POPULADA] Usuário não autenticado');
                    this._routingService.login();
                    return false;
                }

                const podeExecutar = ambienteDemo && carregarBase;
                if (!podeExecutar) {
                    console.log(
                        '[BASE POPULADA] Não pode executar carregar base populada'
                    );
                    this._routingService.login();
                    return false;
                }

                return podeExecutar;
            })
        );
    }
}
