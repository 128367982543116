import { NgModule } from '@angular/core';
import { TextboxLabelGestioModule } from './textbox-label/textbox-label.module';
import { TextboxLabelLargeGestioModule } from './textbox-label-large/textbox-label-large.module';
import { TextboxGestioModule } from './textbox/textbox.module';

@NgModule({
    exports: [
        TextboxGestioModule,
        TextboxLabelGestioModule,
        TextboxLabelLargeGestioModule,
    ],
})
export class TextboxsComponentsGestioModule {}
