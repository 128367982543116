import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContratacaoPowerBIApiService } from './store/contratacao-powerbi-api.service';

@Component({
    selector: 'app-contratacao-powerbi-dialog',
    templateUrl: './contratacao-powerbi-dialog.component.html',
    styleUrls: ['./contratacao-powerbi-dialog.component.scss'],
})
export class ContratacaoPowerBIDialogComponent implements OnInit {
    public title: string;
    public message: string;

    textoLivre: FormControl;
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ContratacaoPowerBIDialogComponent>,
        private apiService: ContratacaoPowerBIApiService
    ) {}

    ngOnInit() {
        this.textoLivre = new FormControl('', Validators.required);

        this.form = new FormGroup({
            textoLivre: this.textoLivre,
        });
    }

    confirmar(): void {
        if (this.form.invalid) {
            return;
        }

        const texto = this.textoLivre.value;

        this.apiService.contratarPainelBI(texto).subscribe(() => {
            this.dialogRef.close(true);
        });
    }
}
