import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FirstStepsContentComponent } from './components/areas/first-steps-content/first-steps-content.component';
import { UIComponentsButtonsGestioModule } from './components/buttons/ui-components-buttons-gestio.module';
import { TabsGestioModule } from './components/tabs/tabs.module';
import { TextboxsComponentsGestioModule } from './components/textbox/textboxs-components.module';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, FlexLayoutModule],
    declarations: [FirstStepsContentComponent],
    exports: [
        FirstStepsContentComponent,
        UIComponentsButtonsGestioModule,
        TextboxsComponentsGestioModule,
        TabsGestioModule,
    ],
})
export class UIComponentsGestioModule {}
