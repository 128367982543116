import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'gestio-first-steps-content',
    templateUrl: './first-steps-content.component.html',
    styleUrls: ['./first-steps-content.component.scss'],
})
export class FirstStepsContentComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
