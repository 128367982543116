import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'gestio-option-button',
    templateUrl: './option-button.component.html',
    styleUrls: ['./option-button.component.scss'],
})
export class OptionButtonComponent implements OnInit {
    @Input() text: string = 'Mais ações';
    @Input() enabled: boolean = true;
    @Input() tooltip: string = null;
    @Input() styleClass: string = null;
    @Input() icon: string = 'keyboard_arrow_down';
    @Output() onClick = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    actionClick() {
        this.onClick.emit();
    }
}
