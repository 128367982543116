import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material';
import {
    DxPopoverModule,
    DxTemplateModule,
    DxTooltipModule,
} from 'devextreme-angular';

import { TextboxGestioModule } from './../textbox/textbox.module';
import { TextboxLabelLargeComponent } from './textbox-label-large.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxPopoverModule,
        DxTemplateModule,
        MatTooltipModule,
        DxTooltipModule,

        TextboxGestioModule,
    ],
    declarations: [TextboxLabelLargeComponent],
    exports: [TextboxLabelLargeComponent],
})
export class TextboxLabelLargeGestioModule {}
