import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DxTextBoxModule } from 'devextreme-angular';

import { TextboxComponent } from './textbox.component';

@NgModule({
    imports: [ReactiveFormsModule, DxTextBoxModule],
    declarations: [TextboxComponent],
    exports: [TextboxComponent],
})
export class TextboxGestioModule {}
