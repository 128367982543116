import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DxTextBoxComponent } from 'devextreme-angular';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'gestio-textbox',
    templateUrl: './textbox.component.html',
    styleUrls: ['./textbox.component.scss'],
})
export class TextboxComponent implements OnInit {
    @Input() control: FormControl = new FormControl(null, []);
    @Input() mask: string = null;
    @Input() placeholder: string = '';
    @Input() width: string = '100%';
    @Input() height: string = '100%';
    @Input() showClearButton: boolean = true;
    @Input() useMaskedValue: boolean = true;
    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;
    @Input() errorInvalid: string = `Valor inválido.`;
    @Input() maskInvalidMessage: string = `Formato inválido.`;
    @Input() focus$: Subject<boolean> = new Subject<boolean>();

    @Output() onValidMask = new EventEmitter<any>();
    @Output() onClearClick = new EventEmitter<any>();
    @Output() onEnterKeyEvent = new EventEmitter<any>();

    @ViewChild(DxTextBoxComponent) textBox: DxTextBoxComponent;

    focusSubscription: Subscription;

    constructor() {}

    ngOnInit() {
        this.focusSubscription = this.focus$
            .pipe(filter((b: boolean) => b == true))
            .subscribe(() => {
                setTimeout(() => {
                    if (this.textBox && this.textBox.instance) {
                        this.textBox.instance.focus();
                    }
                }, 0);
            });
    }

    ngOnDestroy() {
        this.focusSubscription.unsubscribe();
    }

    ngOnChanges() {}

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            // console.log("CONTROL ERROR ->", this.control.errors, this.control.valid);
            if (this.control.errors.required) {
                return { message: this.errorRequired };
            } else if (this.control.errors.minlength) {
                return {
                    message: `Deve possuir no mínimo ${this.control.errors.minlength.requiredLength} caracteres.`,
                };
            } else if (this.control.errors.maxlength) {
                return {
                    message: `Deve possuir no máximo ${this.control.errors.maxlength.requiredLength} caracteres.`,
                };
            } else if (this.control.errors.invalidMask) {
                return { message: this.maskInvalidMessage };
            } else {
                if (this.errorInvalid) {
                    return { message: this.errorInvalid };
                } else {
                    return { message: `Inválido.` };
                }
            }
        }
    }

    private _getOnlyValue(v: string) {
        let r = v.trim();
        return r.replace(/\-/g, '');
    }

    actionValueChanged(e: any) {
        // console.log("VALUE CHANGED", e);
        if (e && e.value && this.mask && this.textBox) {
            if (this.textBox.isValid) {
                this.onValidMask.next(e.value);
            } else {
                let v = this._getOnlyValue(e.value);
                // console.log(`VALUE: [${v}] - MASK: [${this.mask}]`)
                if (v == null || v.length == 0) {
                    this.textBox.isValid = true;
                } else this.control.setErrors({ invalidMask: true });
            }
        }
    }

    actionContentReady(e: any) {
        // console.log("CONTENT READY", e);
        const selector = e.element.querySelector('.dx-icon-clear');
        // console.log("SELECTOR", selector);
        if (selector) {
            selector.addEventListener('click', () => this.onClearClick.emit());
        }
    }

    actionOnEnterKey(e: any) {
        // console.log('a', e);
        // console.log('->', this.control.value);
        this.onEnterKeyEvent.emit(this.control.value);
    }
}
