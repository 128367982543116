import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DxPopoverModule,
    DxTextBoxModule,
    DxValidationGroupModule,
    DxValidatorModule,
} from 'devextreme-angular';

import { CmpLoginTextboxEmailComponent } from './cmp-login-textbox-email.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        DxTextBoxModule,
        DxValidatorModule,
        DxValidationGroupModule,
        DxPopoverModule,
    ],
    declarations: [CmpLoginTextboxEmailComponent],
    exports: [CmpLoginTextboxEmailComponent],
})
export class LoginTextboxEmailModule {}
