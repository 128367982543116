import { NgModule } from '@angular/core';

import { TextareaLabelModule } from './textarea-label/textarea-label.module';
import { TextareaModule } from './textarea/textarea.module';
import { TextboxDisabledLabelModule } from './textbox-disabled-label/textbox-disabled-label.module';
import { LogTextboxDisabledLabelModule } from './log-textbox-disabled-label/log-textbox-disabled-label.module';
import { TextboxDisabledLabelButtonModule } from './textbox-disabled-label-button/textbox-disabled-label-button.module';
import { TextboxEmailModule } from './textbox-email/textbox-email.module';
import { LoginTextboxEmailModule } from './login-textbox-email/login-textbox-email.module';
import { TextboxLabelModule } from './textbox-label/textbox-label.module';
import { TextboxLabelLargeModule } from './textbox-label-large/textbox-label-large.module';
import { TextboxLabelCenterModule } from './textbox-label-center/textbox-label-center.module';
import { LoginTextboxLabelModule } from './login-textbox-label/login-textbox-label.module';
import { TextboxSearchModule } from './textbox-search/textbox-search.module';
import { TextboxSenhaModule } from './textbox-senha/textbox-senha.module';
import { LoginTextboxSenhaModule } from './login-textbox-senha/login-textbox-senha.module';
import { NewPasswordTextboxSenhaModule } from './new-password-textbox-senha/new-password-textbox-senha.module';
import { TextboxModule } from './textbox/textbox.module';

@NgModule({
    exports: [
        TextareaModule,
        TextboxDisabledLabelModule,
        LogTextboxDisabledLabelModule,
        TextboxDisabledLabelButtonModule,
        TextareaLabelModule,
        TextboxLabelLargeModule,
        TextboxModule,
        TextboxLabelModule,
        TextboxLabelCenterModule,
        LoginTextboxLabelModule,
        TextboxSearchModule,
        TextboxEmailModule,
        TextboxSenhaModule,
        LoginTextboxEmailModule,
        LoginTextboxSenhaModule,
        NewPasswordTextboxSenhaModule,
    ],
})
export class TextboxsComponentsModule {}
