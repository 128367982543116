import { NgModule } from '@angular/core';
import {
    DxAccordionModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxDropDownBoxModule,
    DxDropDownButtonModule,
    DxFileUploaderModule,
    DxGalleryModule,
    DxListModule,
    DxLookupModule,
    DxNumberBoxModule,
    DxPopoverModule,
    DxPopupModule,
    DxRadioGroupModule,
    DxSelectBoxModule,
    DxTabsModule,
    DxTemplateModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxToastModule,
    DxToolbarModule,
    DxTooltipModule,
} from 'devextreme-angular';

@NgModule({
    exports: [
        DxAccordionModule,
        // DxChartModule,
        // DxColorBoxModule,
        DxButtonModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxDropDownButtonModule,
        DxFileUploaderModule,
        DxListModule,
        DxDropDownBoxModule,
        DxLookupModule,
        DxNumberBoxModule,
        DxPopoverModule,
        DxRadioGroupModule,
        DxSelectBoxModule,
        DxTemplateModule,
        DxTextAreaModule,
        DxTextBoxModule,
        DxToolbarModule,
        DxGalleryModule,
        DxPopupModule,
        DxTabsModule,
        // DxValidatorModule,
        // DxValidationGroupModule,
        // DevExtremeModule,
        // DxDrawerModule,
        // DxScrollViewModule,
        // DxValidationSummaryModule,
        DxTooltipModule,
        DxToastModule,
        // DxBoxModule
    ],
})
export class DevextremeComponentsModule {}
