import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'otk-cmp-login-textbox-email',
    templateUrl: './cmp-login-textbox-email.component.html',
    styleUrls: ['./cmp-login-textbox-email.component.scss'],
})
export class CmpLoginTextboxEmailComponent implements OnInit {
    @Input() control: FormControl = new FormControl(null, []);
    @Input() placeholder: string = '';
    @Input() width: string = '100%';
    @Input() label: string = 'Seu e-mail';
    @Input() errorRequired: string = `Campo obrigatório`;
    @Input() errorInvalid: string = `Informe um e-mail válido`;

    constructor() {}

    ngOnInit() {}

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            if (this.control.errors.required) {
                return { message: this.errorRequired };
            } else {
                return {
                    message: this.errorInvalid
                        ? this.errorInvalid
                        : `Inválido.`,
                };
            }
        }
    }
}
