import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { AppRoutingService } from '../../app-routing.service';
import { AuthService } from '../services/auth.service';
import { SistemaAuthService } from '../sistema/sistema-auth.service';

@Injectable()
export class ExportacaoGuard implements CanActivate, CanLoad {
    constructor(
        private _routingService: AppRoutingService,
        private _authService: AuthService,
        private _sistemaAuthService: SistemaAuthService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this._permissaoExportacao();
    }

    canLoad(route: Route): Observable<boolean> {
        return this._permissaoExportacao();
    }

    private _permissaoExportacao(): Observable<boolean> {
        this._authService.recuperarLogin();

        return this._sistemaAuthService.acessarExportacao$.pipe(
            first(),
            map((r) => {
                if (!r) {
                    this._routingService.sistemaAvisoSemPermissao();
                    return false;
                }
                return true;
            })
        );
    }
}
