import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material';
import {
    DxPopoverModule,
    DxTemplateModule,
    DxTooltipModule,
} from 'devextreme-angular';

import { TextboxGestioModule } from './../textbox/textbox.module';
import { TextboxLabelComponent } from './textbox-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxPopoverModule,
        DxTemplateModule,
        MatTooltipModule,
        TextboxGestioModule,
        DxTooltipModule,
    ],
    declarations: [TextboxLabelComponent],
    exports: [TextboxLabelComponent],
})
export class TextboxLabelGestioModule {}
