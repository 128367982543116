import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'gestio-import-button',
    templateUrl: './import-button.component.html',
    styleUrls: ['./import-button.component.scss'],
})
export class ImportarButtonComponent implements OnInit {
    @Input() text: string = 'Importar';
    @Output() onClick = new EventEmitter<any>();
    constructor() {}

    ngOnInit() {}

    actionClick() {
        this.onClick.emit();
    }
}
